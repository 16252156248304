import React from "react"
import { Routes } from "../../data/routes"
import { HDTFAQs } from "../../data/faq"
import Card from "../../components/card"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Img from "../../components/img"
import CTA from "../../components/CTA"

import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"

enum ComparisonPoints {
  staffing = "Staffing and Training",
  groupSize = "Group Size",
  curriculum = "Curriculum",
  progress = "Progress Monitoring",
  scheduling = "Scheduling",
  frequency = "Frequency",
}

const Content = {
  pageName: "School Day Integrations - High Dosage Tutoring for Math",
  headerImage: "high-dosage-tutoring-cover.jpg",
  h1: "High Dosage Tutoring and School Day Integrations",
  header:
    "Yup enables schools to integrate effective tutoring into the school day",
  description: `Yup's Math Learning Support System flexibly integrates into
    the school day to meet the specific needs of individual districts, schools,
    or even classrooms.  We collaborate with schools to develop effective
    tutoring programs shaped by teachers and administrators.`,

  // Quote section
  quote: `The research on high-dosage tutoring — generally defined as
    one-on-one tutoring or tutoring in very small groups at least three
    times a week, or for about 50 hours over a semester — is robust, and
    it is convincing. On average, the effect sizes are among the largest
    of all interventions seen in education.`,
  quoteName: "Education Week",
  quoteDate: "August 19, 2020",

  // Settings section
  settingsHeader: `Yup's flexibility allows schools to leverage expert tutors
    to support students with a diverse range of needs in a variety of settings`,
  settings: [
    {
      header: "High Dosage Tutoring",
      description: `Students meet with a tutor regularly in order to receive more
        intensive, ongoing support. Yup captures data on individual student progress
        that helps to inform continued support.`,
      image: "icon-enrichment.svg",
    },
    {
      header: "Targeted Test Preparation",
      description: `Yup works with teachers and administrators to develop strategic
        test preparation plans.  Yup tutors are equipped to support students in
        preparing for state assessments, college-entry tests and even Advanced
        Placement exams.`,
      image: "icon-target.svg",
    },
    {
      header: "Remote Learning Support",
      description: `Yup allows students to instantly connect with a live tutor
        from anywhere - at school, home, or even the library. In a setting where
        teacher and parent support may be limited, students are able to receive
        as much expert help as they need for as long as they need it.`,
      image: "icon-school.svg",
    },
    {
      header: "24/7 Instructional Support and Homework Help",
      description: `Students receive around the clock, seven days a week access to
        tutoring support so they can get expert help whenever they need it -
        during instructional support blocks, study hall or even late at night
        when studying for a test. The options are limitless!`,
      image: "icon-24.svg",
    },
  ],

  // What is HDT section
  definitionHeader: "What is High-Dosage Tutoring?",
  definition: `High-dosage tutoring represents a proven intervention to accelerate
    learning and resolve unfinished learning. It has been defined by researchers
    as more than three tutoring sessions per week or about 50 hours per semester.
    Since tutors can offer individualized instruction tailored to each student's
    needs, high dosage tutoring should not be viewed merely as a remedial tool.

    Yup's approach to high-dosage tutoring offers one-on-one tutoring sessions.
    These sessions can be scheduled, although Yup's platform allows on demand
    access for students as well. Yup tutors are carefully screened for math
    expertise and teaching skills. Only the top 5% of applicants are accepted.
    Yup tutors are current and former teachers, education professionals, and
    graduate students with extensive math experience. Moreover, Yup tutors are
    supported every day by Tutor Quality Managers to ensure sessions drive
    long-term understanding, not just answer-getting.`,
  definitionImage: "purchase-complete.svg",

  // Implementation section
  impelementationHeader:
    "What Does It Take to Implement High-Dosage Tutoring Well?",
  implementationText: `Personalized tutoring is core to a successful approach. Yup's
    highly trained math tutors only work with one student at a time.
    Yup tutors engage students in cycles of questioning and feedback to
    precisely diagnose student misunderstandings and support students
    to gain enduring, conceptual understanding. For Yup tutors, it's not
    about helping students to answer a question; it's about supporting
    them to gain strong conceptual understanding.

    Yup recruits tutors specifically for math content knowledge and their
    ability to teach according to evidence-based strategies supported
    by the National Council of Teachers of Mathematics (NCTM). Each Yup
    tutor works with a Tutor Quality Manager to ensure adherence to the
    three pillars of
    <a href="${Routes.learningFundamentals}" target="_blank" class="text-secondary">Yup's Teaching Framework</a>:
    Empathy, Pedagogy, and Precision.

    In addition to offering excellent tutors, Yup's approach ensures strong
    curriculum alignment and includes robust progress monitoring protocols.
    Research shows that the curriculum used during tutoring sessions should
    be aligned to the curriculum used throughout the school. So Yup partners
    with ASSISTments, a research based platform that allows teachers to
    seamlessly assign problems to students from all major math curricula
    (Illustrative Mathematics, Eureka Math, and more). And because we know
    that teacher capacity is limited, Yup supports with progress monitoring
    by reporting each week on key trends with aligned recommendations.
    Yup also makes session transcripts and usage statistics available in real
    time for teachers to review on their schedule.`,

  // Comparison section
  comparisonHeader: "Yup and High-Dosage Tutoring",
  comaprisonText: `Given the effectiveness of High-Dosage Tutoring as evidenced
    by significant research, we wanted to showcase how Yup stacks up against
    the primary design principles.`,
  comparison: [
    {
      name: "Research",
      details: {
        [ComparisonPoints.staffing]: `The most effective tutors are teachers and
          those with subject-specific experience and expertise.`,
        [ComparisonPoints.groupSize]: `1-on-1 or 2-on-1 tutoring is ideal, but up to 4-on-1
          can be effective.`,
        [ComparisonPoints.curriculum]: `The curriculum used during tutoring should be
          aligned to the curriculum used throughout the school.`,
        [ComparisonPoints.progress]: `Tutoring programs that utilize ongoing informal
          assessments allow tutors to more effectively tailor their instruction.`,
        [ComparisonPoints.scheduling]: `Educators should tutor students during blocks of time when
          students do not have core classes like math or reading.`,
        [ComparisonPoints.frequency]: `Every school day for an hour.`,
      },
    },
    {
      name: "Reality",
      details: {
        [ComparisonPoints.staffing]: `Teachers are already shouldering a full course
          load, and a national teacher shortage in secondary math makes additional
          hiring a challenge.`,
        [ComparisonPoints.groupSize]: `In order to support just 10 students, schools would need to
          find 5 highly qualified math tutors. Supporting 50 students would require
          25. This is neither sustainable nor realistic.`,
        [ComparisonPoints.curriculum]: `Acquainting a cohort of tutors with the core curriculum and
          providing them with the aligned materials requires a significant time investment.`,
        [ComparisonPoints.progress]: `The responsibility of creating assessments and familiarizing
          tutors with them often falls on teachers who are already planning and teaching a
          full course load.`,
        [ComparisonPoints.scheduling]: `Teachers and support educators are needed during core instructional blocks.
          When these same staff run high-dosage tutoring during the school day, it eats into prep
          time and is often in violation of contracts.`,
        [ComparisonPoints.frequency]: `Paying for daily tutoring can be expensive, and schools must pay tutors
          even when students don't show up.`,
      },
    },
    {
      name: "Yup",
      details: {
        [ComparisonPoints.staffing]: `Yup recruits tutors specifically for math content knowledge
          and their ability to teach according to evidence-based strategies supported by the
          National Council of Teachers of Mathematics (NCTM).`,
        [ComparisonPoints.groupSize]: `Yup's highly trained math tutors only work with 1 student at a time
          in order to maximize their effectiveness.`,
        [ComparisonPoints.curriculum]: `Yup partners with ASSISTments, a research-based platform that allows
          teachers to seamlessly assign problems to students from all major math curricula
          (Illustrative Mathematics, Eureka Math, and more).`,
        [ComparisonPoints.progress]: `Yup session transcripts and usage statistics are available
          in real time. Our teacher support team reports out on key trends with aligned
          recommendations each week.`,
        [ComparisonPoints.scheduling]: `Yup's on-demand 24/7 scheduling model allows for tremendous flexibility,
          with tutors available instantly at any time of day.`,
        [ComparisonPoints.frequency]: `Yup charges a fixed cost per student for the school year for unlimited access
          to our math tutors, 24/7. Our team partners with teachers and administrators to seamlessly
          integrate tutoring into the school day, guaranteeing usage and learning for the students
          who need it most.`,
      },
    },
  ],

  // Testimonial section
  testimonial: `Because students can submit a problem directly from what I
    assigned, Yup tutors were able to provide support that directly related
    to what we were doing in the classroom. Pair that with the excellent
    tutoring and I was thrilled with our classroom engagement & student results.`,
  testimonialName: "Teacher",
  testimonialLocation: "Common Ground High School",
  testimonialImg: "high-dosage-tutoring-testimonial.jpeg",

  // FAQ section
  faqHeader: "Frequently Asked Questions",
  faqs: HDTFAQs,

  // Closing CTA
  closingCTA: "Learn what it means to bring Yup to your school or district",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule demo",
    },
  ],
}

function Header() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-a3c09c7a3b8f4bd90d280722eaff9634
  return (
    <div className="relative bg-background overflow-hidden flex flex-col-reverse lg:block">
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Img
          className="h-48 w-full sm:h-56 md:h-72 lg:w-full lg:h-full"
          src={Content.headerImage}
          alt=""
          cover={true}
        />
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-background lg:max-w-2xl lg:w-full">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-background transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-1 pt-12 lg:pb-8">
            <h1 className="">
              <span className="text-base font-semibold text-secondary tracking-wide uppercase">
                {Content.h1}
              </span>
            </h1>
            <div className="sm:text-center lg:text-left">
              <span className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:pr-8">
                {Content.header}
              </span>
              {Content.description.split(/\n\n/g).map(description => (
                <p key={description} className="mt-3 text-lg lg:pr-24">
                  {description}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Quote() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/testimonials#component-fd7b8bd425f42f6504b22e1ecc6b43c9
  return (
    <div className="bg-secondary mb-8 p-8">
      <div className="mx-auto max-w-7xl">
        <blockquote className="mt-10">
          <div className="max-w-4xl mx-auto text-center text-2xl leading-9 font-medium text-white">
            <p>&ldquo;{Content.quote}&rdquo;</p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base font-medium text-white">
                  {Content.quoteName}
                </div>

                <svg
                  className="hidden md:block mx-1 h-5 w-5 text-primary"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>

                <div className="text-base font-medium text-gray-100">
                  {Content.quoteDate}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  )
}

function SettingsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-fea10362c98dcf74d601fab911a1aee3
  return (
    <Card>
      <p className="mt-2 text-gray-900 text-center text-4xl font-extrabold tracking-tight">
        {Content.settingsHeader}
      </p>
      {Content.settings.map((value, index) => (
        <div className="mb-8" key={value.header}>
          <div
            className={`flex flex-col-reverse items-center ${
              index % 2 ? "md:flex-row-reverse" : "md:flex-row"
            }`}
          >
            <div className="w-full p-8 md:w-2/3 text-center md:text-left">
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {value.header}
                </h2>
                {value.description.split(/\n\n/g).map(description => (
                  <p key={description} className="mt-3 text-lg lg:pr-24">
                    {description}
                  </p>
                ))}
              </div>
            </div>
            <div className="w-full mt-12 sm:mt-16 lg:mt-0 md:w-1/3">
              <div className="p-4 sm:px-6 lg:px-0 lg:m-0 max-w-full">
                <Img
                  className="w-full max-w-sm max-h-48 rounded-xl mx-auto"
                  src={value.image}
                  alt={value.header}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}

function Comparison() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/pricing#component-466fb280c935eaf1bbc06080419b751f
  return (
    <Card>
      <div className="mb-8 lg:text-center lg:mb-12">
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {Content.comparisonHeader}
        </p>
        <p className="mt-2 text-lg leading-8 font-extrabold tracking-tight text-gray-500">
          {Content.comaprisonText}
        </p>
      </div>
      {/* xs to lg */}
      <div className="max-w-2xl mx-auto space-y-8 lg:hidden">
        {Object.values(ComparisonPoints).map(point => (
          <section key={point}>
            <div className="px-4 py-4">
              <h2 className="text-xl leading-6 font-bold text-gray-900">
                {point}
              </h2>
            </div>

            {Content.comparison.map(comparison => (
              <table key={`${comparison.name}-${point}`} className="w-full">
                <caption className="bg-gray-50 py-2 px-4 text-sm font-medium text-gray-900 text-left">
                  {comparison.name}
                </caption>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td
                      className="p-2 px-6 text-sm text-gray-500 text-left align-text-top"
                      scope="row"
                    >
                      {comparison.details[point]}
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </section>
        ))}
      </div>

      {/* lg+ */}
      <div className="hidden lg:block">
        <table className="w-full h-px table-fixed">
          <thead>
            <tr>
              <th></th>
              {Content.comparison.map(comparison => (
                <th
                  key={comparison.name}
                  className="w-1/4 pb-4 px-6 text-xl leading-6 font-bold text-gray-900 text-left"
                  scope="col"
                >
                  {comparison.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border-t border-gray-100 divide-y divide-gray-200">
            {Object.values(ComparisonPoints).map(point => (
              <tr key={point}>
                <td className="py-2 pl-6 font-bold text-gray-900 text-left align-text-top">
                  {point}
                </td>
                {Content.comparison.map(comparison => (
                  <td
                    className="py-2 px-6 text-sm text-gray-500 text-left align-text-top"
                    scope="row"
                  >
                    {comparison.details[point]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  )
}

function LongTextSection(props: {
  header: string
  text: string
  image?: string
}) {
  return (
    <Card>
      <div className="md:grid grid-cols-3">
        {props.image && (
          <Img
            src={props.image}
            alt={props.header}
            className="h-48 md:w-full md:h-auto p-4 mt-8"
          />
        )}
        <div className={`${props.image ? "col-span-2" : "col-span-3"} p-4`}>
          <p className="mt-2 text-gray-900 text-4xl font-extrabold tracking-tight">
            {props.header}
          </p>
          {props.text.split(/\n\n/g).map(__html => (
            <p
              key={__html}
              className="mt-3 text-lg lg:pr-24"
              dangerouslySetInnerHTML={{ __html }}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

function Testimonial() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/testimonials#component-54ee9cbce13afa3082f12b00ff1c00a6
  return (
    <Card>
      <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
        <div className="relative lg:flex lg:items-center">
          <div className="hidden lg:block lg:flex-shrink-0">
            <Img
              className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
              src={Content.testimonialImg}
              alt=""
              cover={true}
            />
          </div>

          <div className="relative lg:ml-10">
            <svg
              className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-secondary-200 opacity-50"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
              aria-hidden="true"
            >
              <path
                strokeWidth={2}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <div className="text-2xl leading-9 font-medium text-gray-900">
                <p>{Content.testimonial}</p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    <Img
                      className="h-12 w-12 rounded-full"
                      src={Content.testimonialImg}
                      alt=""
                      cover={true}
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="text-base font-medium text-gray-900">
                      {Content.testimonialName}
                    </div>
                    <div className="text-base font-medium text-secondary">
                      {Content.testimonialLocation}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </Card>
  )
}

function FAQSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1

  return (
    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200 py-12">
      <h1 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
        {Content.faqHeader}
      </h1>
      <dl className="mt-6 space-y-6 divide-y divide-gray-200">
        {Content.faqs.map(faq => (
          <Disclosure as="div" key={faq.question} className="pt-6">
            {({ open }) => (
              <>
                <dt className="text-lg">
                  <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                    <span className="font-medium text-gray-900">
                      {faq.question}
                    </span>
                    <span className="ml-6 h-7 flex items-center">
                      <ChevronDownIcon
                        className={`h-6 w-6 transform ${
                          open ? "-rotate-180" : "rotate-0"
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                  <p
                    className="text-base text-gray-500"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
    </div>
  )
}

const HighDosageTutoring = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        route={Routes.highDosageTutoring}
        description={Content.description}
      />
      <Header />
      <Quote />
      <SettingsSection />
      <LongTextSection
        header={Content.definitionHeader}
        text={Content.definition}
        image={Content.definitionImage}
      />
      <Comparison />
      <LongTextSection
        header={Content.impelementationHeader}
        text={Content.implementationText}
      />
      <Testimonial />
      <FAQSection />
      <CTA title={Content.closingCTA} actions={Content.closingCTAActions} />
    </Layout>
  )
}

export default HighDosageTutoring
